import React, { Component } from 'react';
import Menu from '../../Menu/Menu';
import Footer from '../../Footer/Footer';
import { withTranslation } from 'react-i18next';
// import { process.env.REACT_APP_API} from './../../../CONST/constant';
class BeginReset extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  ValidateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  SubmitReset = async (e) => {
    const url = process.env.REACT_APP_API + '/api/users/forget';
    e.preventDefault();
    const {
      email: { value: email },
    } = e.target;
    if (this.ValidateEmail(email)) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        const json = await response.json();
        //console.log(json);
        if (json.status === 'success') {
          document.getElementsByClassName('result')[0].innerText = json.message;
        } else {
          if (json.status === 'fail') {
            document.getElementsByClassName('result')[0].innerText =
              json.message;
          }
        }
      } else {
        console.error('something went wrong');
        const json = await response.json();
        if (json.status === 'fail') {
          document.getElementsByClassName('result')[0].innerText = json.message;
        }
      }
    } else {
      document.getElementsByClassName('result')[0].innerText =
        'You have entered an invalid email address!';
    }
  };
  render() {
    const { email } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Menu />
        <div className="row justify-content-center">
          <h1 className="mtop-title">{t('main.begin-rest-pw.h1')}</h1>
        </div>
        <div className="row justify-content-center mb-5">
          <div className=" col-md-3 text-center ">
            <form id="form-log" onSubmit={this.SubmitReset}>
              <h2>{t('main.begin-rest-pw.h2')}</h2>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-large"
              >
                {t('main.begin-rest-pw.button')}
              </button>
            </form>
            <div className="result"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const BeginResetPassword = withTranslation()(BeginReset);

export default BeginResetPassword;
