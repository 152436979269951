import React, { Component } from 'react';
import Menu from './../../Menu/Menu';
import { withTranslation } from 'react-i18next';
import Footer from './../../Footer/Footer';
import './policy.css';
class Privacypolicy extends Component {
  render() {
    // const { t } = this.props;

    return (
      <div>
        <Menu />
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="text-center  mtop-title">
              POLITIQUE DE CONFIDENTIALITÉ
            </h1>
          </div>
          <div className="row justify-content-center">
            <div className=" col-md-8 text-center ">
              <div className="result">
                <br />
                www.keynegdo.com
                <br />
                Joachim Amsellem
                <br />
                Type de site : e-commerce (Service Web)
              </div>
            </div>
            <div className=" col-md-10 ">
              <div className="result">
                <div>
                  <div className="text-left">
                    <h2 className="title-policy">
                      Le but de cette politique de confidentialité
                    </h2>
                    <div>
                      Le but de cette politique de confidentialité est
                      d'informer les utilisateurs de notre site des données
                      personnelles que nous recueillerons ainsi que les
                      informations suivantes, le cas échéant :
                    </div>
                    <br />
                    <ul>
                      <li>Les données personnelles que nous recueillerons </li>
                      <li>L’utilisation des données recueillies </li>
                      <li> Qui a accès aux données recueillies </li>
                      <li> Les droits des utilisateurs du site</li>
                      <li>La politique de cookies du site</li>
                    </ul>
                    <br />
                    Cette politique de confidentialité fonctionne parallèlement
                    aux conditions générales d’utilisation de notre site.
                    <br />
                    <h2 className="title-policy">Lois applicables </h2>
                    Conformément au Règlement général sur la protection des
                    données (RGPD), cette politique de confidentialité est
                    conforme aux règlements suivants.
                    <br />
                    Les données à caractère personnel doivent être :
                    <br />
                    <ul>
                      <li>
                        traitées de manière licite, loyale et transparente au
                        regard de la personne concernée (licéité, loyauté,
                        transparence);
                      </li>
                      <li>
                        collectées pour des finalités déterminées, explicites et
                        légitimes, et ne pas être traitées ultérieurement d'une
                        manière incompatible avec ces finalités; le traitement
                        ultérieur à des fins archivistiques dans l'intérêt
                        public, à des fins de recherche scientifique ou
                        historique ou à des fins statistiques n'est pas
                        considéré, conformément à l'article 89, paragraphe 1,
                        comme incompatible avec les finalités initiales
                        (limitation des finalités);
                      </li>
                      <li>
                        {' '}
                        adéquates, pertinentes et limitées à ce qui est
                        nécessaire au regard des finalités pour lesquelles elles
                        sont traitées (minimisation des données);
                      </li>
                      <li>
                        {' '}
                        exactes et, si nécessaire, tenues à jour; toutes les
                        mesures raisonnables doivent être prises pour que les
                        données à caractère personnel qui sont inexactes, eu
                        égard aux finalités pour lesquelles elles sont traitées,
                        soient effacées ou rectifiées sans tarder (exactitude);
                      </li>
                      <li>
                        {' '}
                        conservées sous une forme permettant l'identification
                        des personnes concernées pendant une durée n'excédant
                        pas celle nécessaire au regard des finalités pour
                        lesquelles elles sont traitées; les données à caractère
                        personnel peuvent être conservées pour des durées plus
                        longues dans la mesure où elles seront traitées
                        exclusivement à des fins archivistiques dans l'intérêt
                        public, à des fins de recherche scientifique ou
                        historique ou à des fins statistiques conformément à
                        l'article 89, paragraphe 1, pour autant que soient mises
                        en œuvre les mesures techniques et organisationnelles
                        appropriées requises par le règlement afin de garantir
                        les droits et libertés de la personne concernée
                        (limitation de la conservation);
                      </li>
                      <li>
                        {' '}
                        traitées de façon à garantir une sécurité appropriée des
                        données à caractère personnel, y compris la protection
                        contre le traitement non autorisé ou illicite et contre
                        la perte, la destruction ou les dégâts d'origine
                        accidentelle, à l'aide de mesures techniques ou
                        organisationnelles appropriées (intégrité et
                        confidentialité).
                      </li>
                    </ul>
                    <br />
                    Le traitement n'est licite que si, et dans la mesure où, au
                    moins une des conditions suivantes est remplie :
                    <br />
                    <lu>
                      <li>
                        la personne concernée a consenti au traitement de ses
                        données à caractère personnel pour une ou plusieurs
                        finalités spécifiques;
                      </li>
                      <li>
                        {' '}
                        le traitement est nécessaire à l'exécution d'un contrat
                        auquel la personne concernée est partie ou à l'exécution
                        de mesures précontractuelles prises à la demande de
                        celle-ci;
                      </li>
                      <li>
                        {' '}
                        le traitement est nécessaire au respect d'une obligation
                        légale à laquelle le responsable du traitement est
                        soumis;
                      </li>
                      <li>
                        {' '}
                        le traitement est nécessaire à la sauvegarde des
                        intérêts vitaux de la personne concernée ou d'une autre
                        personne physique;
                      </li>
                      <li>
                        {' '}
                        le traitement est nécessaire à l'exécution d'une mission
                        d'intérêt public ou relevant de l'exercice de l'autorité
                        publique dont est investi le responsable du traitement;
                      </li>
                      <li>
                        {' '}
                        le traitement est nécessaire aux fins des intérêts
                        légitimes poursuivis par le responsable du traitement ou
                        par un tiers, à moins que ne prévalent les intérêts ou
                        les libertés et droits fondamentaux de la personne
                        concernée qui exigent une protection des données à
                        caractère personnel, notamment lorsque la personne
                        concernée est un enfant.
                      </li>
                    </lu>
                    <br />
                    Pour les résidents de l’État de Californie, cette politique
                    de confidentialité vise à se conformer à la California
                    Consumer Privacy Act (CCPA). S’il y a des incohérences entre
                    ce document et la CCPA, la législation de l’État
                    s’appliquera. Si nous constatons des incohérences, nous
                    modifierons notre politique pour nous conformer à la loi
                    pertinente.
                    <h2 className="title-policy">Consentement </h2>
                    Les utilisateurs conviennent qu’en utilisant notre site, ils
                    consentent à :
                    <br />
                    <lu>
                      <li>
                        a. les conditions énoncées dans la présente politique de
                        confidentialité et
                      </li>
                      <li>
                        b. la collecte, l’utilisation et la conservation des
                        données énumérées dans la présente politique.
                      </li>
                    </lu>
                    <h2 className="title-policy">
                      Données personnelles que nous collectons
                    </h2>
                    <div className="subtitle-policy">
                      Données collectées automatiquement
                    </div>
                    Lorsque vous visitez et utilisez notre site, nous pouvons
                    automatiquement recueillir et conserver les renseignements
                    suivants :
                    <lu>
                      <li>Adresse IP</li>
                    </lu>
                    <div className="subtitle-policy">
                      Données recueillies de façon non automatique
                    </div>
                    Nous pouvons également collecter les données suivantes
                    lorsque vous effectuez certaines fonctions sur notre site :
                    <br />
                    <lu>
                      <li>Prénom et nom </li>
                      <li>Âge</li>
                      <li> Date de naissance</li>
                      <li> Sexe</li>
                      <li> Email</li>
                      <li> Numéro de téléphone </li>
                      <li>Domicile</li>
                      <li> Données de remplissage automatique</li>
                      <li>Entreprise</li>
                      <li> Profession </li>
                      <li>Réseau Sociaux </li>
                      <li>Photo</li>
                      <li> Lien Url</li>
                      <li>Calendrier </li>
                    </lu>
                    <br />
                    Ces données peuvent être recueillies au moyen des méthodes
                    suivantes :
                    <lu>
                      <li>Enregistement d'un compte</li>
                      <li>Création de carte de visite virtuel </li>
                      <li>Création de qrcode pour un lien URL</li>
                      <li>
                        Création des événements pour des Agendas éléctronique
                      </li>
                      <li>Création de page Web vitrine sur notre site</li>
                    </lu>
                    <br />
                    Veuillez noter que nous ne collectons que les données qui
                    nous aident à atteindre l’objectif énoncé dans cette
                    politique de confidentialité. Nous ne recueillerons pas de
                    données supplémentaires sans vous en informer au préalable.
                    <h2 className="title-policy">
                      Comment nous utilisons les données personnelles
                    </h2>
                    Les données personnelles recueillies sur notre site seront
                    utilisées uniquement aux fins précisées dans la présente
                    politique ou indiquées sur les pages pertinentes de notre
                    site. Nous n’utiliserons pas vos données au-delà de ce que
                    nous divulguerons.
                    <br />
                    Les données que nous recueillons automatiquement sont
                    utilisées aux fins suivantes :
                    <br />
                    <lu>
                      <li>Statistiques</li>
                      <li> sécurité du site</li>
                    </lu>
                    Les données que nous recueillons lorsque l’utilisateur
                    exécute certaines fonctions peuvent être utilisées aux fins
                    suivantes :
                    <br />
                    <lu>
                      <li>Communication</li>
                    </lu>
                    <h2 className="title-policy">
                      Avec qui nous partageons les données personnelles
                    </h2>
                    <div className="subtitle-policy">Employés</div>
                    Nous pouvons divulguer à tout membre de notre organisation
                    les données utilisateur dont il a raisonnablement besoin
                    pour réaliser les objectifs énoncés dans la présente
                    politique.
                    <div className="subtitle-policy">Tierces parties</div>
                    Nous pouvons partager les données utilisateur avec les tiers
                    suivants : __________
                    <br />
                    Nous pouvons partager les données utilisateur avec des tiers
                    aux fins suivantes : __________
                    <br />
                    Les tiers ne seront pas en mesure d’accéder aux données des
                    utilisateurs au-delà de ce qui est raisonnablement
                    nécessaire pour atteindre l’objectif donné.
                    <div className="subtitle-policy">Autres divulgations</div>
                    Nous nous engageons à ne pas vendre ou partager vos données
                    avec des tiers, sauf dans les cas suivants :
                    <br />
                    <lu>
                      <li>1) si la loi l'exige</li>
                      <li>
                        2) si elle est requise pour toute procédure judiciaire
                      </li>
                      <li> 3) pour prouver ou protéger nos droits légaux</li>
                      <li>
                        4) à des acheteurs ou des acheteurs potentiels de cette
                        société dans le cas où nous cherchons à la vendre la
                        société
                      </li>
                    </lu>
                    <br />
                    Si vous suivez des hyperliens de notre site vers un autre
                    site, veuillez noter que nous ne sommes pas responsables et
                    n’avons pas de contrôle sur leurs politiques et pratiques de
                    confidentialité.
                    <h2 className="title-policy">
                      Combien de temps nous stockons les données personnelles
                    </h2>
                    Les données des utilisateurs seront conservées pour une
                    durée de :5 ans
                    <br />
                    Nous veillerons à ce que les utilisateurs soient avisés si
                    leurs données sont conservées plus longtemps que cette
                    durée.
                    <h2 className="title-policy">
                      Comment nous protégeons vos données personnelles
                    </h2>
                    Afin d’assurer la protection de votre sécurité, nous
                    utilisons le protocole de sécurité de la couche transport
                    pour transmettre des renseignements personnels dans notre
                    système .
                    <br />
                    Toutes les données stockées dans notre système sont bien
                    sécurisées et ne sont accessibles qu’à nos employés. Nos
                    employés sont liés par des accords de confidentialité
                    stricts et une violation de cet accord entraînerait le
                    licenciement de l'employé.
                    <br />
                    Alors que nous prenons toutes les précautions raisonnables
                    pour nous assurer que nos données d’utilisateur sont
                    sécurisées et que les utilisateurs sont protégés, il reste
                    toujours du risque de préjudice. L’Internet en sa totalité
                    peut être, parfois, peu sûr et donc nous sommes incapables
                    de garantir la sécurité des données des utilisateurs au-delà
                    de ce qui est raisonnablement pratique.
                    <h2 className="title-policy">
                      Transferts internationaux de données
                    </h2>
                    Les données utilisateur peuvent être consultées, traitées ou
                    collectées dans les pays suivants :
                    <lu>
                      <li>Royaume-uni </li>
                      <li> Etats-Unis </li>
                      <li> France </li>
                      <li> Espagne </li>
                      <li> Belgique </li>
                      <li>Allemagne </li>
                      <li> Grece </li>
                      <li> Pologne </li>
                      <li> Israel </li>
                      <li> Italy</li>
                    </lu>
                    <h2 className="title-policy">Mineurs </h2>
                    Le RGPD précise que les personnes de moins de 15 ans sont
                    considérées comme des mineurs aux fins de la collecte de
                    données. Les mineurs doivent avoir le consentement d’un
                    représentant légal pour que leurs données soient
                    recueillies, traitées et utilisées.
                    <h2 className="title-policy">
                      Vos droits en tant qu’utilisateur
                    </h2>
                    En vertu du RGPD, les utilisateurs ont les droits suivants
                    en tant que personnes concernées :
                    <br />
                    <lu>
                      <li>droit d’accès </li>
                      <li>droit de rectification </li>{' '}
                      <li>droit à l’effacement </li>{' '}
                      <li>droit de restreindre le traitement </li>{' '}
                      <li>droit à la portabilité des données </li>{' '}
                      <li>droit d'objection</li>
                    </lu>
                    <br />
                    Vous trouverez de plus amples informations sur ces droits au
                    chapitre 3 (art 12-23) du RGPD.
                    <h2 className="title-policy">
                      Comment modifier, supprimer ou contester les données
                      recueillies
                    </h2>
                    Si vous souhaitez que vos renseignements soient supprimés ou
                    modifiés d’une façon ou d’une autre, veuillez communiquer
                    avec notre agent de protection de la vie privée ici :
                    <br />
                    Joachim : contact-us@keynegdo.com
                    <h2 className="title-policy">Politique sur les cookies</h2>
                    Un cookie est un petit fichier, stocké sur le disque dur
                    d’un utilisateur par le site Web. Son but est de recueillir
                    des données relatives aux habitudes de navigation de
                    l’utilisateur.
                    <br />
                    Nous utilisons les types de cookies suivants sur notre site:
                    <div className="subtitle-policy">Cookies fonctionnels</div>
                    Nous les utilisons pour mémoriser toutes les sélections que
                    vous faites sur notre site afin qu’elles soient sauvegardées
                    pour vos prochaines visites.
                    <br />
                    Vous pouvez choisir d’être averti chaque fois qu’un cookie
                    est transmis. Vous pouvez également choisir de désactiver
                    les cookies entièrement dans votre navigateur Internet, mais
                    cela peut diminuer la qualité de votre expérience
                    d’utilisation.
                    <h2 className="title-policy">Modifications </h2>
                    Cette politique de confidentialité peut être modifiée à
                    l’occasion afin de maintenir la conformité avec la loi et de
                    tenir compte de tout changement à notre processus de
                    collecte de données. Nous recommandons à nos utilisateurs de
                    vérifier notre politique de temps à autre pour s’assurer
                    qu’ils soient informés de toute mise à jour. Au besoin, nous
                    pouvons informer les utilisateurs par courriel des
                    changements apportés à cette politique.
                    <h2 className="title-policy">Contact </h2>
                    Si vous avez des questions à nous poser, n’hésitez pas à
                    communiquer avec nous en utilisant ce qui suit :
                    contact-us@keynegdo.com
                    <br />
                    Date d’entrée en vigueur : le 15 octobre 2021
                    <div className="text-center">
                      © 2002-2021, DocumentsLégaux™ (Sequiter Inc.)
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const PrivacyPolicy = withTranslation()(Privacypolicy);

export default PrivacyPolicy;
