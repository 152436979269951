import React, { Component } from 'react';
import './Pricing.css';
import { useTranslation } from 'react-i18next';

export const pricingDataAll = {
  regular: {
    one: {
      title: 'main.home.one.title',
      period: 'main.home.one.period',
      price: 'main.home.one.price',
      old_price: '',
      link: 'main.home.one.link',
      mylist: [
        { text: 'main.home.one.mylist.text1' },
        { text: 'main.home.one.mylist.text2' },
        { text: 'main.home.one.mylist.text3' },
        // { text: 'main.home.one.mylist.text4' },
        // { text: 'main.home.one.mylist.text5' },
      ],
    },
    two: {
      title: 'main.home.two.title',
      period: 'main.home.two.period',
      price: 'main.home.two.price',
      old_price: '100',
      link: 'main.home.two.link',
      mylist: [
        { text: 'main.home.two.mylist.text1' },
        { text: 'main.home.two.mylist.text2' },
        { text: 'main.home.two.mylist.text3' },
        // { text: 'main.home.two.mylist.text4' },
        // { text: 'main.home.two.mylist.text5' },
        // { text: 'main.home.two.mylist.text6' },
      ],
    },
    three: {
      title: 'main.home.three.title',
      period: 'main.home.three.period',
      price: 'main.home.three.price',
      old_price: '',
      link: 'main.home.three.link',
      mylist: [
        { text: 'main.home.three.mylist.text1' },
        { text: 'main.home.three.mylist.text2' },
        { text: 'main.home.three.mylist.text3' },
        // { text: 'main.home.three.mylist.text4' },
        // { text: 'main.home.three.mylist.text5' },
        // { text: 'main.home.three.mylist.text6' },
      ],
    },
  },
};

function AddInfoList(props) {
  const { t } = useTranslation();
  const mylist = props.additonalInfo.mylist;
  const listItems = mylist.map((info, i) => (
    <li key={i}>
      <span>
        <div className="row">
          {/* <div className="col icon-check">
            <i className="fa fa-price fa-check" />
          </div> */}
          <div className="col">{t(info.text)}</div>
        </div>
      </span>
    </li>
  ));
  let oldprice = '';
  if (props.additonalInfo.old_price !== '') {
    oldprice = t(props.additonalInfo.old_price) + '€';
  }
  return (
    <div className="card-body">
      <h5 className="card-title text-muted text-uppercase text-center">
        {t(props.additonalInfo.title)}
      </h5>
      <h6 className="card-price text-center">
        {t(props.additonalInfo.price)}€
        <span className="period">{t(props.additonalInfo.period)}</span>
      </h6>
      <span className="price-lineed  text-center">{oldprice}</span>
      <br></br>
      <ul className="fa-ul">{listItems}</ul>
      <a
        href={t(props.additonalInfo.link)}
        className="btn btn-block btn-primary text-uppercase"
      >
        {t('main.home.price.button.begin')}
      </a>
    </div>
  );
}

export class Pricing extends Component {
  render() {
    let pricingData = pricingDataAll.regular;

    return (
      <section className="pricing py-5" id="price">
        <div className="container">
          <div className="row justify-center">
            {/* <div className="col-lg-4 flex justify-center"> */}
            <div className="col-lg-6 flex justify-center">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <AddInfoList additonalInfo={pricingData.one} />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 flex justify-center"> */}
            <div className="col-lg-6 flex justify-center">
              <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                  <AddInfoList additonalInfo={pricingData.two} />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 flex justify-center">
              <div className="card">
                <div className="card-body">
                  <AddInfoList additonalInfo={pricingData.three} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}
