import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class Contactform extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: '',
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { t } = this.props;
    const { name, email, message } = this.state;
    return (
      <div className="row justify-content-center">
        <div className="col-md-4">
          <h1 className="text-center mtop-title">
            {t('main.contact-form.h1')}
          </h1>
          <form id="form-log">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              onChange={this.onChange}
              value={name}
              required="required"
              placeholder={t('main.contact-form.name')}
            />

            <input
              type="text"
              className="form-control"
              name="email"
              value={email}
              onChange={this.onChange}
              placeholder="Email"
              required="required"
            />
            <textarea
              className="form-control"
              name="message"
              value={message}
              onChange={this.onChange}
              placeholder={t('main.contact-form.textearea')}
              aria-label="With textarea"
            ></textarea>
            <button
              type="submit"
              className="btn btn-primary btn-block btn-large"
            >
              {t('main.contact-form.sendmessage')}
            </button>
          </form>
          <div dir="auto" className="text-center">
            <a href="/signup">
              <span className=""> {t('main.contact-form.register')}</span>
            </a>
          </div>
          <div className="result"></div>
          <br></br>
        </div>
      </div>
    );
  }
}
const ContactForm = withTranslation()(Contactform);

export default ContactForm;
