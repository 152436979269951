import React from 'react';
import './ContentHome.css';
import imgphone from './../../../img/model-phone.webp';
import imgsocial from './../../../img/social.webp';
import imgQrcode from './../../../img/testqrcode-2.png';
import { useTranslation } from 'react-i18next';

function WhatisThisService() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-center">
        <div id="whatthat">
          <h2 className="text-center titleh2">
            {t('main.home.contenthome.h2.what')}
          </h2>
        </div>
      </div>

      <div className="card-what">
        <div className="row ">
          <div className="col-md-5 text-center">
            <img width="150" src={imgphone} alt="phone" className="myphone" />
          </div>
          <div className="col-md-7 px-3">
            <div className="card-block px-6">
              <h3 className="card-title">
                {t('main.home.contenthome.h3.virtalbusiness')}
              </h3>
              <p className="card-text">
                {t('main.home.contenthome.h3.virtalbusiness.p1')}
              </p>
              <p className="card-text">
                {t('main.home.contenthome.h3.virtalbusiness.p2')}
              </p>
              <p className="card-text">
                {t('main.home.contenthome.h3.virtalbusiness.p3')}
              </p>
              <br></br>
              <div className="text-center">
                {/* <a href="#" className="mt-auto btn btn-primary  ">
                  Read More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-what float-left">
        <div className="row align-middle">
          <div className="col-sm-7">
            <div className="card-block">
              <h3 className="card-title">
                {t('main.home.contenthome.h3.whythisservice')}
              </h3>
              <p className="card-text">
                {t('main.home.contenthome.h3.whythisservice.p1')}
              </p>
              <p className="card-text">
                {t('main.home.contenthome.h3.whythisservice.p2')}
              </p>
              <p className="card-text">
                {t('main.home.contenthome.h3.whythisservice.p3')}
              </p>
            </div>
          </div>

          <div className="col-sm-5 text-center">
            <img width="150" src={imgphone} alt="phone" />
          </div>
        </div>
      </div>

      <div className="card-what float-right">
        <div className="row align-middle ">
          <div className="col-sm-5 text-center">
            <img width="280" src={imgsocial} alt="social-network" />
          </div>
          <div className="col-sm-7">
            <div className="card-block">
              <h3 className="text-center">
                {t('main.home.contenthome.h3.typeofinfo')}
              </h3>
              <p>{t('main.home.contenthome.h3.typeofinfo.p1')}</p>
              <p>{t('main.home.contenthome.h3.typeofinfo.p2')}</p>
              <p>{t('main.home.contenthome.h3.typeofinfo.p3')}</p>
              <p>{t('main.home.contenthome.h3.typeofinfo.p4')} </p>
              <p>{t('main.home.contenthome.h3.typeofinfo.p5')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center ">
        <div className="col-md-12 px-3">
          <div className="card-block px-6">
            <h3 className=" card-title text-center">
              {t('main.home.contenthome.h3.getdesign')}
            </h3>
            <p className="card-text">
              {t('main.home.contenthome.h3.getdesign.p1')}
            </p>
            <p className="card-text">
              {t('main.home.contenthome.h3.getdesign.p2')}{' '}
            </p>
            <p className="card-text">
              {t('main.home.contenthome.h3.getdesign.p3')}
            </p>
            <br></br>
            <div className="text-center">
              <img width="280" src={imgQrcode} alt="social-network" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhatisThisService;
