import React, { createRef } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import SwitchLanguage from './SwitchLanguage';

function Menu() {
  const { t } = useTranslation();
  const wrapper = createRef();
  const Sigin = process.env.REACT_APP_SIGNIN;
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      className="navbar-bg shadow-lg fixed-top "
    >
      <Navbar.Brand href="/#home" className="textcolor">
        Keynegdo
      </Navbar.Brand>
      <SwitchLanguage />
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        ref={wrapper}
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <Nav.Link href="/#whatthat" className="text-center textcolor">
            {t('main.menu.what')}
          </Nav.Link>
          <Nav.Link href="/#howthatwork" className="text-center textcolor">
            {t('main.menu.how')}
          </Nav.Link>
          <Nav.Link href="/#price" className="text-center textcolor">
            {t('main.menu.price')}
          </Nav.Link>
          <Nav.Link href="/signup" className="text-center textcolor">
            {t('main.menu.signup')}
          </Nav.Link>
          <Nav.Link href={Sigin} className="text-center textcolor">
            {t('main.menu.signin')}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default Menu;
