import React from 'react';
import Menu from './../../Menu/Menu';
import Footer from './../../Footer/Footer';

function legalNotion() {
  return (
    <div>
      <Menu />
      <div
        className="row justify-content-center"
        style={{ marginBottom: '20px' }}
      >
        <div className="col-md-8">
          <h1 className="text-center  mtop-title">MENTION LEGALE</h1>
        </div>
        <div className="col-md-10">
          <section className="showcase text-left">
            <div className="container-fluid p-0">
              <div className="row g-0">
                <div className="col-lg-6 ">
                  <h2>Contenu du site Web</h2>
                  <p className="lead mb-0">
                    L'auteur n'assume aucune garantie quant à la pertinence,
                    l'exactitude, l'exhaustivité ou la qualité du contenu
                    fourni. Les réclamations en responsabilité contre l'auteur
                    concernant des dommages matériels ou intellectuels ou
                    d'autres résultats préjudiciables résultant de l'utilisation
                    ou de la non-utilisation d'informations incomplètes ou
                    incorrectes seront donc rejetées, sauf en cas de négligence
                    intentionnelle ou grave de la part de l'auteur. être prouvé.
                    Toutes les offres sont susceptibles d'être modifiées et sont
                    sans engagement. L'auteur se réserve le droit exprès de
                    modifier, compléter ou supprimer des parties du contenu en
                    ligne sans préavis ou d'interrompre tout ou partie du
                    contenu en ligne de manière permanente ou temporaire.
                  </p>
                </div>
                <div className="col-lg-6 my-auto showcase-text">
                  <h2>Liens</h2>
                  <p className="lead mb-0">
                    L'auteur ne peut être tenu pour responsable des renvois
                    directs ou indirects vers d'autres sites Internet (liens)
                    pour lesquels l'auteur n'est pas directement responsable que
                    s'il a connaissance du contenu des sites en question et s'il
                    est en mesure de , et a la capacité technique d'empêcher
                    l'utilisation de contenus illégaux. L'auteur déclare par la
                    présente qu'au moment où les liens ont été placés pour la
                    première fois sur le site, aucun contenu illégal n'était
                    apparent sur les sites liés. L'auteur n'a aucune influence
                    sur la conception actuelle ou future, le contenu et la
                    paternité des sites liés. L'auteur ne peut donc être tenu
                    pour responsable des modifications apportées aux sites après
                    la mise en place du lien sur le site. En outre, l'auteur ne
                    peut être tenu responsable ni des liens ni des publications
                    et messages publiés par les utilisateurs des forums de
                    discussion, livres d'or, listes de diffusion, ou d'autres
                    installations similaires fournies sur le propre site de
                    l'auteur. Tout contenu illégal, incorrect ou incomplet, et
                    tout dommage ou perte résultant de l'utilisation ou de la
                    non-utilisation de ces informations, est de la
                    responsabilité du fournisseur du site vers lequel un lien a
                    été fait et non de la personne qui a simplement fait le
                    lien. à la publication en question.
                  </p>
                </div>
              </div>

              <div className="row g-0">
                <div className="col-lg-6 ">
                  <h2>Droit d'auteur et droit des marques</h2>
                  <p className="lead mb-0">
                    Dans toutes les publications, l'auteur s'est efforcé de
                    respecter les lois sur le droit d'auteur concernant tous les
                    graphiques, documents audio, séquences vidéo et textes qui
                    ont été utilisés ; d'utiliser, dans la mesure du possible,
                    les propres graphismes, documents audio, séquences vidéo et
                    textes de l'auteur ; ou d'employer des graphiques, des
                    documents audio, des séquences vidéo et des textes qui sont
                    du domaine public. Toutes les marques déposées et déposées
                    apparaissant dans le contenu Internet de l'auteur et
                    protégées par des tiers sont intégralement soumises aux
                    dispositions de la loi sur le droit d'auteur et au droit de
                    propriété applicables au propriétaire enregistré dans chaque
                    cas. Il ne faut pas supposer que, du simple fait qu'une
                    marque est nommée, les marques déposées ne sont pas
                    protégées par les droits des tiers ! Le droit d'auteur pour
                    le matériel publié créé par l'auteur reste la propriété de
                    l'auteur des pages.
                  </p>
                </div>
                <div className="col-lg-6 my-auto showcase-text">
                  <h2>Data Protection</h2>
                  <p className="lead mb-0">
                    Ici vous pouvez trouver notre politique de confidentialité :
                    <a href="/privacy-policy">Politique de confidentialité</a>
                  </p>
                  <br />
                  <h2>Force juridique de cette clause de non-responsabilité</h2>
                  <p className="lead mb-0">
                    Cette clause de non-responsabilité doit être considérée
                    comme faisant partie de la publication Internet à partir de
                    laquelle vous avez été renvoyé. Si des sections de ce texte,
                    ou des formulations individuelles, ne sont pas, ou ne sont
                    plus, entièrement conformes à la loi en vigueur, le contenu
                    ou la validité des autres parties de ce document n'en sont
                    pas affectés.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default legalNotion;
