import React, { Component } from 'react';
import './Header.css';
import imgQrcode from './../../../img/testqrcode-2.png';
import { withTranslation } from 'react-i18next';
class MyHeader extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="masthead2">
        <div className="row align-items-center header2">
          <div className="col">
            <div className="headerborder">
              <h1 className="marginbottom-5">{t('main.home.header2.h1')}</h1>
              <p className="lead"></p>
              <div className="">{t('main.home.header2.thebest')}</div>
              <div className="marginbottom-5">
                {t('main.home.header2.stopprint')}
              </div>
              <div className="buttonstart margintop-5">
                <a className="" href="/signup">
                  <button type="button" className="btn btn-primary btn-lg">
                    {t('main.home.header2.buttoncreate')}
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col try">
            <div className="headerborder margintop-20 ">
              <p className="lead"></p>
              <div className="text-center">
                <img
                  className=""
                  width={200}
                  height={200}
                  src={imgQrcode}
                  alt="Third slide"
                />
              </div>
              {/* <div className="marginbottom-5  text-center tryme">
                {t('main.home.header2.tryme')}
              </div> */}
              <div className="text-center marginbottom-5">
                <b>
                  <i>{t('main.home.header2.takephone')}</i>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const Header = withTranslation()(MyHeader);

export default Header;
