import React, { Component } from 'react';
import {} from 'react-bootstrap';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

export const linkCategory = [
  // { link: '#', text: 'main.footer.category1' },
  // { link: '#', text: 'main.footer.category2' },
  // { link: '#', text: 'main.footer.category3' },
  // { link: '#', text: 'main.footer.category4' },
  // { link: '#', text: 'main.footer.category5' },
];

export const linkQuickLink = [
  { link: '/contact-us', text: 'main.footer.contactus' },
  // { link: '#', text: 'main.footer.aboutus' },
  { link: '/privacy-policy', text: 'main.footer.policy' },
  { link: '/terms-condition', text: 'main.footer.termscondition' },
  { link: '/legal-notion', text: 'main.footer.legalnotion' },
  // { link: '#', text: 'SitMap' },
];

export const linkSocialNetwork = [
  // { class: 'dribbble', link: '#', text: '' },
  { class: 'facebook', link: '#', text: '' },
  { class: 'twitter', link: '#', text: '' },
  { class: 'instagram', link: '#', text: '' },
  { class: 'linkedin', link: '#', text: '' },
  // { class: 'pinterest', link: '#', text: '' },
];
function AddSocialLink(props) {
  //  const { t } = useTranslation();
  const mylist = props.mylist;
  const listItems = mylist.map((info, i) => (
    <li key={i}>
      <a className={info.class} href={info.link}>
        <i className={`fa fa-` + info.class}></i>
      </a>
    </li>
  ));
  return <ul className="social-icons">{listItems}</ul>;
}

function AddList(props) {
  const { t } = useTranslation();
  const mylist = props.mylist;
  const listItems = mylist.map((info, i) => (
    <li key={i}>
      <a href={info.link}>{t(info.text)}</a>
    </li>
  ));
  return <ul className="footer-links">{listItems}</ul>;
}
class FooterBuild extends Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h6>{t('main.footer.aboutus')}</h6>
              <p className="text-justify">{t('main.footer.aboutus.text')}</p>
            </div>

            {/* <div className="col-xs-6 col-md-3">
              <h6>{t('main.footer.category')}</h6>
              <AddList mylist={linkCategory} />
            </div> */}

            <div className="col-xs-12 col-md-6">
              <h6>{t('main.footer.quicklink')}</h6>
              <AddList mylist={linkQuickLink} />
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">
                © 2020 Copyright:
                <a href={t('main.company.url')}> {t('main.company.com')}</a>.
              </p>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <AddSocialLink mylist={linkSocialNetwork} />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
const Footer = withTranslation()(FooterBuild);
export default Footer;
