import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './contactus.css';
// const Telephone = ['+12345678910', '+12345678911'];
const Email = ['contact-us@keynegdo.com'];
// function Address(props) {
//   const result = (
//     <span>
//       {props.number}, {props.street} <br />
//       {props.city}, {props.country}
//     </span>
//   );
//   return result;
// }
function ListContact(props) {
  const mylist = props.list.map((contact) => (
    <span>
      <a href={props.Mytype + contact}>{contact}</a>
      <br />
    </span>
  ));
  return mylist;
}
class Contactinfo extends Component {
  render() {
    const { t } = this.props;

    return (
      <div>
        {/* <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="myFrame"
              width="100%"
              height="100%"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed"
            />
          </div>
        </div> */}
        <div className="mg-contact">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h3> {t('main.contact-info.h3')}</h3>
                </div>
              </div>
            </div>
            <div className="row  justify-content-center">
              {/* <div className="col-md-4">
                <div className="single-contact-box text-center">
                  <div>{t('main.contact-info.mobile')}</div>
                  <ListContact Mytype="tel:" list={Telephone} />
                </div>
              </div> */}
              <div className="col-md-4">
                <div className="single-contact-box text-center">
                  <div>{t('main.contact-info.email')}</div>
                  <ListContact Mytype="mailto:" list={Email} />
                </div>
              </div>
              {/* <div className="col-md-4">
                <div className="single-contact-box text-center">
                  <div>{t('main.contact-info.address')}</div>
                  <Address
                    number="125/A"
                    street="CA Commercial Area"
                    city="California"
                    country="USA"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ContactInfo = withTranslation()(Contactinfo);

export default ContactInfo;
