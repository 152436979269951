import React, { Component } from 'react';
import './ContentHome.css';
import { ReactComponent as SVGPeople } from './../../../img/people.svg';
import { ReactComponent as SVGProfesionel } from './../../../img/professionel.svg';

import imgQrcodeid from './../../../img/testqrcode-coeur.png';

// import { MyCarousel } from './Carousel';
import { withTranslation } from 'react-i18next';
import WhatisThisService from './ServiceExplanation';
import ReasonToUse from './ReasonToUse';
import ProDetailUse from './Prodetailuse';

class Choiceuserpro extends Component {
  handleClick(i) {
    if (i === 'user') {
      document.getElementsByClassName('pro')[0].style.display = 'none';
      document.getElementsByClassName('user')[0].style.display = '';
      document.getElementById('usertext').className = 'inline-block  active';
      document.getElementById('protext').className = 'inline-block';
    } else {
      document.getElementsByClassName('user')[0].style.display = 'none';
      document.getElementsByClassName('pro')[0].style.display = '';
      document.getElementById('usertext').className = 'inline-block';
      document.getElementById('protext').className = 'inline-block active';
    }
  }
  //Run this after html mounted
  hideProStart() {
    document.getElementsByClassName('pro')[0].style.display = 'none';
  }
  componentDidMount() {
    this.hideProStart();
  }
  render() {
    const { t } = this.props;
    return (
      <ul className="nav nav-fill  shadow-lg nav-tabs">
        <li className="nav-item">
          <div
            className="active inline-block "
            id="usertext"
            onClick={() => this.handleClick('user')}
          >
            <div className="icon">
              <SVGPeople />
            </div>
            <p className="marginbottom-5">{t('main.home.contenthome.user')}</p>
          </div>
        </li>
        <li className="nav-item">
          <div
            className="inline-block"
            id="protext"
            onClick={() => this.handleClick('pro')}
          >
            <div className="icon">
              <SVGProfesionel />
            </div>
            <p className="marginbottom-5"> {t('main.home.contenthome.pro')}</p>
          </div>
        </li>
      </ul>
    );
  }
}
const ChoiceUserPro = withTranslation()(Choiceuserpro);

class Contenthome extends Component {
  render() {
    const { t } = this.props;

    return (
      <section className="section">
        <ReasonToUse />

        {/* <MyCarousel /> */}
        <WhatisThisService />
        <div className="row justify-content-center text-center">
          <div className="" id="howthatwork">
            <h2 className="text-center titleh2">
              {t('main.home.contenthome.h3.howthatwork')}
            </h2>
          </div>
        </div>

        <div className="user-pro">
          <ChoiceUserPro />
          <div className="user">
            <div className="row justify-content-center">
              <div className=" mr-5 ml-5">
                <p className="text-center">
                  <strong>{t('main.home.contenthome.user.p0')}</strong>
                </p>
                <div className="">
                  <span className=""></span>
                </div>
                <p className="">{t('main.home.contenthome.user.p1')}</p>
                <p className="">{t('main.home.contenthome.user.p2')}</p>
                <div className="text-center">
                  <img width="280" src={imgQrcodeid} alt="qrcode-id" />
                </div>
                <p className="text-center">
                  <strong>{t('main.home.contenthome.user.p3')}</strong>
                  <span
                    aria-hidden="true"
                    className="fas fa-arrow-right"
                  ></span>
                </p>
                <p className="">{t('main.home.contenthome.user.p4')}</p>
                <p className="">{t('main.home.contenthome.user.p5')}</p>
                <p className="">{t('main.home.contenthome.user.p6')} </p>
              </div>
            </div>
          </div>
          <ProDetailUse />
          {/* <div className="pro">
            <p className=" text-center">
              <strong>{t('main.home.contenthome.pro.p0')}</strong>
            </p>
            <div className=" row justify-content-center">
              <div className="col-6 col-md-4 text-center">
                <p className="">{t('main.home.contenthome.pro.p1')}</p>
                <img
                  width="280"
                  src={imgInscription}
                  alt="inscription-website"
                />
              </div>

              <div className="col-6 col-md-4 text-center">
                <p className="">{t('main.home.contenthome.pro.p2')}</p>
                <img
                  width="280"
                  src={imgVirtualCard}
                  alt="virtual-card-example"
                />
              </div>

              <div className="col-6 col-md-4 text-center">
                <p className="">{t('main.home.contenthome.pro.p3')}</p>
                <img width="280" src={imgQrcodeid2} alt="qrcode-id2" />
              </div>
            </div>
          </div> */}
        </div>
      </section>
    );
  }
}
const ContentHome = withTranslation()(Contenthome);

export default ContentHome;
