import React, { Component } from 'react';
import Helmet from 'react-helmet';

import Header from './Header';
import ContentHome from './ContentHome';
// import { MyCarousel } from './Carousel';
import { Pricing } from './Pricing';
import Footer from './../../Footer/Footer';
import './Home.css';
import Menu from './../../Menu/Menu';
import { withTranslation } from 'react-i18next';
export class HomeSite extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Keynegdo</title>
          <link rel="canonical" href="https://www.keynegdo.com/" />
          <meta
            name="description"
            content={t('main.home.helmet.description')}
          />
          <meta
            name="keywords"
            content="business card, carte de visite, Qrcode, Contact, reseau sociaux , Social media, URL , Design QRcode, échange, exchange "
          />
        </Helmet>
        <Menu />
        <Header />
        <ContentHome />
        <Pricing></Pricing>
        <Footer />
      </div>
    );
  }
}
const Home = withTranslation()(HomeSite);

export default Home;
