import React, { Component } from 'react';
import Menu from './../../Menu/Menu';
import { withTranslation } from 'react-i18next';
import Footer from './../../Footer/Footer';
// import { process.env.REACT_APP_API } from './../../../CONST/constant';
class Resetpassword extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      passwordverif: '',
      result: '',
    };
  }
  changeResult(str) {
    this.setState({
      result: str,
    });
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  isOkPass(p) {
    let anUpperCase = /[A-Z]/;
    let aLowerCase = /[a-z]/;
    let aNumber = /[0-9]/;
    // let aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    let obj = {};
    obj.result = true;

    if (p.length < 8) {
      obj.result = false;
      obj.error = 'Not long enough! minimum 8 characters ';
      return obj;
    }

    let numUpper = 0;
    let numLower = 0;
    let numNums = 0;
    // let numSpecials = 0;
    for (var i = 0; i < p.length; i++) {
      if (anUpperCase.test(p[i])) numUpper++;
      else if (aLowerCase.test(p[i])) numLower++;
      else if (aNumber.test(p[i])) numNums++;
      // else if (aSpecial.test(p[i])) numSpecials++;
    }

    if (numUpper < 1 || numLower < 1 || numNums < 1) {
      obj.result = false;
      obj.error =
        'Wrong Format: Your Password must contain 1 Uppercase 1 Lowercase 1 Number ';
      return obj;
    }
    return obj;
  }
  SubmitReset = async (e) => {
    const url = process.env.REACT_APP_API + '/api/users/pwreset';
    const token = window.location.search.split('?token=')[1];
    localStorage.setItem('sctu', token);
    // doc.cookie = `auth_snac=${token}`
    e.preventDefault();
    const {
      password: { value: password },
      passwordverif: { value: passwordverif },
    } = e.target;

    const verifComplex = await this.isOkPass(password);
    if (verifComplex.result) {
      if (password !== passwordverif) {
        console.error('passord and confirm password are different');
        this.changeResult('You have entered an invalid Password');
        // document.getElementsByClassName('result')[0].innerText =
        //   'You have entered an invalid Password';
        return;
      } else {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + token,
          },
          body: JSON.stringify({ password, token }),
        });
        if (response.ok) {
          const json = await response.json();
          //console.log(json);
          if (json.status === 'success') {
            this.changeResult(json.message);
            // document.getElementsByClassName('result')[0].innerText =
            //   json.message;
            window.location.assign('/signin');
          } else {
            if (json.status === 'fail' && json.num === '2') {
              // document.getElementsByClassName('result')[0].innerText =
              //   json.message;
              this.changeResult(json.message);
            }
          }
        } else {
          //const json = await response.json()
          ////console.log(json)
          this.changeResult(
            'your time is Expired ask for a new request reset password'
          );
          // document.getElementsByClassName('result')[0].innerText =
          //   'your time is Expired ask for a new request reset password';
          console.error('something went wrong');
        }
      }
    } else {
      document.getElementsByClassName('result')[0].innerText =
        verifComplex.error;
    }
  };
  render() {
    const { t } = this.props;
    const { password, passwordverif } = this.state;
    return (
      <div>
        <Menu />

        <div className="row justify-content-center">
          <div className=" col-md-3 text-center ">
            <form id="form-log" onSubmit={this.SubmitReset}>
              <h1 className="mtop-title">{t('main.reset-pw.h1')}</h1>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Enter New Password"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="passwordverif"
                  value={passwordverif}
                  onChange={this.onChange}
                  className="form-control"
                  placeholder="Confirm  New Password"
                  required="required"
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-block btn-large"
              >
                {t('main.reset-pw.letmein')}
              </button>
            </form>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className=" col-md-3 text-center ">
            <div className="result">{this.state.result}</div>
          </div>
        </div>
        <div className="row justify-content-center mb-5">
          <div className=" col-md-3 text-center ">
            <div id="notice-pw">
              <p>{t('main.reset-pw.passwordnotice')}</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const ResetPassword = withTranslation()(Resetpassword);

export default ResetPassword;
