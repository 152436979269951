import React, { Component } from 'react';

import Menu from './../../Menu/Menu';
import { withTranslation } from 'react-i18next';
import Footer from './../../Footer/Footer';
// import { process.env.REACT_APP_API } from './../../../CONST/constant';

const Sigin = process.env.REACT_APP_SIGNIN;
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordverif: '',
      result: '',
      isButtonDisabled: false,
    };
  }
  changeResult(str) {
    this.setState({
      result: str,
    });
  }
  HandleisButtonDisabled = (bool) => {
    this.setState({
      isButtonDisabled: bool,
    });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  ValidateEmail = function (mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  isOkPass(p) {
    let anUpperCase = /[A-Z]/;
    let aLowerCase = /[a-z]/;
    let aNumber = /[0-9]/;
    // let aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/;
    let obj = {};
    obj.result = true;

    if (p.length < 8) {
      obj.result = false;
      obj.error = 'Not long enough! minimum 8 characters ';
      return obj;
    }

    let numUpper = 0;
    let numLower = 0;
    let numNums = 0;
    // let numSpecials = 0;
    for (var i = 0; i < p.length; i++) {
      if (anUpperCase.test(p[i])) numUpper++;
      else if (aLowerCase.test(p[i])) numLower++;
      else if (aNumber.test(p[i])) numNums++;
      // else if (aSpecial.test(p[i])) numSpecials++;
    }

    if (numUpper < 1 || numLower < 1 || numNums < 1) {
      obj.result = false;
      obj.error =
        'Wrong Format: Your Password must contain 1 Uppercase 1 Lowercase 1 Number ';
      return obj;
    }
    return obj;
  }

  SubmitCreate = async (e) => {
    e.preventDefault();
    const Sigin = process.env.REACT_APP_SIGNIN;
    const { firstname, lastname, email, password, passwordverif } = this.state;
    //console.log(firstname);
    const url = process.env.REACT_APP_API + '/api/users/createuser';

    this.HandleisButtonDisabled(true);
    if (this.ValidateEmail(email)) {
      const verifComplex = await this.isOkPass(password);
      if (verifComplex.result) {
        if (password !== passwordverif) {
          console.error('passord and confirm password are different');
          this.changeResult('passord and confirm password are different');
          this.HandleisButtonDisabled(false);
          return;
        } else {
          try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ firstname, lastname, email, password }),
            });

            if (response.ok) {
              const json = await response.json();
              //console.log(json);
              if (json.status === 'success') {
                window.location.assign(Sigin);
              } else {
                if (json.status === 'fail' && json.num === '1') {
                  this.changeResult(json.message);
                  this.HandleisButtonDisabled(false);
                }
              }
            } else {
              const json = await response.json();
              this.changeResult(json.message);
              this.HandleisButtonDisabled(false);
              console.error('something went wrong');
            }
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        this.changeResult(verifComplex.error);
        this.HandleisButtonDisabled(false);
      }
    } else {
      this.changeResult('You have entered an invalid email address!');
      this.HandleisButtonDisabled(false);
    }
  };

  render() {
    const { t } = this.props;
    const { email, password, passwordverif } = this.state;
    return (
      <div>
        <Menu />
        <div className="row justify-content-center">
          <div className="col-md-3">
            <form onSubmit={this.SubmitCreate}>
              <h1 className="text-center mtop-title">{t('main.signup.h1')}</h1>
              <div className="form-group">
                <label>{t('main.signup.email')}</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  placeholder="Enter email"
                  required="required"
                />
              </div>

              <div className="form-group">
                <label>{t('main.signup.password')}</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  placeholder="Enter password"
                  required="required"
                />
              </div>
              <div className="form-group">
                <label>{t('main.signup.confirmpassword')}</label>
                <input
                  type="password"
                  className="form-control"
                  name="passwordverif"
                  value={passwordverif}
                  onChange={this.onChange}
                  placeholder="confirme password"
                  required="required"
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={this.state.isButtonDisabled}
              >
                {t('main.signup.signup')}
              </button>
              <p className="forgot-password text-right">
                {t('main.signup.already')}{' '}
                <a href={Sigin}> {t('main.signup.signin')}</a>
              </p>
            </form>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className=" col-md-3 text-center ">
            <div className="result">{this.state.result}</div>
          </div>
        </div>
        <div className="row justify-content-center  mb-5">
          <div className=" col-md-3 text-center ">
            <div id="notice-pw">
              <p>{t('main.signup.passwordnotice')}</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const SignUp = withTranslation()(Signup);

export default SignUp;
