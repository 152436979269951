import React from 'react';
import { useTranslation } from 'react-i18next';
import './ContentHome.css';
import imgForm from './../../../img/form.webp';
import imgQR from './../../../img/qr.webp';
import imgFormPhone from './../../../img/formphone.webp';
function ReasonToUse() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="row justify-content-center text-center">
        <h2 className="text-center titleh2">
          {t('main.home.contenthome.h2.3reasons')}
        </h2>
      </div>
      <div className="row justify-content-md-center align-items-center mb-5">
        <div className="col-md-3">
          <div className="card ">
            <div className="text-center">
              <img className="card-img-top" src={imgFormPhone} alt="phone" />
            </div>
            <div className="card-body">
              <h3 className="text-center">
                {t('main.home.contenthome.h3.neverlackcard')}
              </h3>
              <p>{t('main.home.contenthome.h3.neverlackcard.p')}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card ">
            <div className="text-center">
              <img className="card-img-top" src={imgForm} alt="form" />
            </div>
            <div className="card-body">
              <h3 className="text-center">
                {t('main.home.contenthome.h3.changedata')}
              </h3>
              <p>{t('main.home.contenthome.h3.changedata.p')}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="text-center">
              <img className="card-img-top" src={imgQR} alt="qrcode" />
            </div>

            <div className="card-body">
              <h3 className="text-center">
                {' '}
                {t('main.home.contenthome.h3.keepsafe')}
              </h3>
              <p>{t('main.home.contenthome.h3.keepsafe.p')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReasonToUse;
