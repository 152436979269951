import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/Pages/Home/Home';
import SignUp from './components/Pages/SignInUp/Signup';
import BeginResetPassword from './components/Pages/ResetPassword/Begin-reset-pw';
import ResetPassword from './components/Pages/ResetPassword/Reset-pw';
import { ContactUS } from './components/Pages/ContactUs/Contact-us';
import Vcard from './components/Pages/Vcards';
import { I18nextProvider } from 'react-i18next';
import TermsConditions from './components/Pages/Legacy/TermsConditions';
import legalNotion from './components/Pages/Legacy/LegalNotion';
import PrivacyPolicy from './components/Pages/Legacy/PrivacyPolicy';
import GetUrlNotAvalaible from './components/Pages/getUrlNotAvalaible';
import './lang/i18n';
import './App.css';

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <I18nextProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Home} exact></Route>
              <Route path="/signup" component={SignUp} exact></Route>
              <Route
                path="/terms-condition"
                component={TermsConditions}
                exact
              ></Route>
              <Route path="/legal-notion" component={legalNotion} exact></Route>
              <Route
                path="/privacy-policy"
                component={PrivacyPolicy}
                exact
              ></Route>

              <Route
                path="/begin-reset-pw"
                component={BeginResetPassword}
                exact
              ></Route>
              <Route path="/reset-pw" component={ResetPassword} exact></Route>

              <Route path="/contact-us" component={ContactUS} exact></Route>
              <Route path="/vcard/:id/:name" component={Vcard} exact></Route>
              <Route
                path="/geturl/not-available"
                component={GetUrlNotAvalaible}
                exact
              ></Route>
            </Switch>
          </BrowserRouter>
        </I18nextProvider>
      </Suspense>
    </div>
  );
}

export default App;
