import React from 'react';
import { useTranslation } from 'react-i18next';
import './ContentHome.css';
import imgInscription from './../../../img/inscription.png';

import imgVirtualCard from './../../../img/virtual-card.webp';
import imgQrcodeid2 from './../../../img/testqrcode-2.png';
function ProDetailUse() {
  const { t } = useTranslation();
  return (
    <div className="pro">
      <div className="row justify-content-center text-center">
        <h2 className="text-center">
          <p className=" text-center">
            <strong>{t('main.home.contenthome.pro.p0')}</strong>
          </p>
        </h2>
      </div>
      <div className="row justify-content-md-center align-items-center mb-5">
        <div className="col-md-3">
          <div className="card ">
            <div className="text-center">
              <img
                className="card-img-top"
                src={imgInscription}
                alt="inscription-website"
              />
            </div>
            <div className="card-body">
              <h3 className="text-center">
                <p className="">{t('main.home.contenthome.pro.p1')}</p>
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card ">
            <div className="text-center">
              <img
                className="card-img-top"
                src={imgVirtualCard}
                alt="virtual-card-example"
              />
            </div>
            <div className="card-body">
              <h3 className="text-center">
                <p className="">{t('main.home.contenthome.pro.p2')}</p>
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="text-center">
              <img
                className="card-img-top"
                src={imgQrcodeid2}
                alt="qrcode-id2"
              />
            </div>

            <div className="card-body">
              <h3 className="text-center">
                <p className="">{t('main.home.contenthome.pro.p3')}</p>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProDetailUse;
