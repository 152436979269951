import React, { useEffect, useState, useCallback } from 'react';
import Menu from './../Menu/Menu';
import { useTranslation } from 'react-i18next';
import Footer from './../Footer/Footer';
import './Vcards.css';
import ModelCardBusiness from './ModelCardBusiness';

//const url1 = 'https://www.keynegdo.com';
const Vcard = (props) => {
  const [result, setResult] = useState('');
  // const [keepName, setKeepName] = useState('');
  const [keepDataUser, setKeeDataUser] = useState({});
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const changeResult = (str) => {
    setResult(str);
  };

  // const getNameCard = () => {
  //   let firstname = props.match.params.name.split('-')[1];
  //   let lastname = props.match.params.name.split('-')[2];
  //   let sentance = firstname + ' ' + lastname;
  //   //console.log('test');
  //   setKeepName(sentance);
  //   // this.setState({ keepName: sentance });
  // };
  const HandleisButtonVisible = (bool) => {
    setIsButtonVisible(bool);
  };
  const HandleisButtonDisabled = (bool) => {
    setIsButtonDisabled(bool);
  };
  const AutomationDownload = useCallback(async () => {
    let Id = props.match.params.id; //vcard
    let Name = props.match.params.name; //vcf
    const iPhone = /iPhone|iPad/i.test(navigator.userAgent);
    const url1 = process.env.REACT_APP_API;
    const BASE_URL =
      url1 + '/api/vcard/download/' + Id + '/' + Name + '?iPhone=' + iPhone;
    try {
      const result = await fetch(BASE_URL);
      if (result.ok) {
        HandleisButtonVisible(true);
        document.location = BASE_URL;
      } else {
        console.log(result);
        HandleisButtonVisible(false);
        changeResult('This card is not available');
      }
    } catch (error) {
      console.log(error);
    }
  }, [props.match.params.name, props.match.params.id]);
  const getInfoCard = useCallback(async () => {
    ///getinfo/:id/:name
    let Id = props.match.params.id; //vcard
    let Name = props.match.params.name; //vcf
    const url1 = process.env.REACT_APP_API;
    const myurl = url1 + '/api/vcard/getinfo/' + Id + '/' + Name;
    try {
      const result = await fetch(myurl);
      if (result.ok) {
        const json = await result.json();
        //  console.log(json);
        setKeeDataUser(json);
      } else {
        changeResult('This card is not available');
      }
    } catch (error) {
      console.log(error);
      // HandleisButtonDisabled(false);
    }
  }, [props.match.params.name, props.match.params.id]);

  const downloadFile = async () => {
    const iPhone = /iPhone|iPad/i.test(navigator.userAgent);
    //console.log(iPhone);
    const buttonDownload = document.createElement('a'); // document.querySelector('#download');
    let Id = props.match.params.id; //vcard
    let Name = props.match.params.name; //vcf
    const url1 = process.env.REACT_APP_API;
    // console.log(url1);
    const myurl =
      url1 + '/api/vcard/download/' + Id + '/' + Name + '?iPhone=' + iPhone;

    try {
      HandleisButtonDisabled(true);
      const result = await fetch(myurl);
      if (result.ok) {
        //console.log('ok button work');
        buttonDownload.href = myurl;
        buttonDownload.target = '_blank';
        buttonDownload.download = Name + '.vcf';
        buttonDownload.click();
        buttonDownload.style.display = '';
        // document.location = myurl;
        HandleisButtonDisabled(false);
      } else {
        ////console.log('test');
        HandleisButtonDisabled(true);
        changeResult('This card is not available');
      }
    } catch (error) {
      //console.log(error);
      HandleisButtonDisabled(false);
    }
  };

  useEffect(() => {
    AutomationDownload();
    getInfoCard();
  }, [AutomationDownload, getInfoCard]);
  const { t } = useTranslation();
  return (
    <div>
      <Menu />
      <div className="row justify-content-center mb-5">
        <div className="col-md-6">
          <h1 className="text-center mtop-title">{t('main.vcard.h1')}</h1>
          {/* <h1 className="text-uppercase text-center">{keepName}</h1> */}
          <div style={{ marginTop: 20 }}>
            <ModelCardBusiness datauser={keepDataUser} />
          </div>
          {isButtonVisible ? (
            <div className="row justify-content-center">
              <div className="col-md-6 text-center">
                <div id="download">
                  <button
                    disabled={isButtonDisabled}
                    className="btn btn-primary btn-block btn-large"
                    onClick={downloadFile}
                  >
                    {t('main.vcard.download')}
                  </button>
                  <p className="text-center">
                    {t('main.vcard.downloadnotice')}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="result">{result}</div>

          <div dir="auto" className="text-center">
            <a href="/signup">
              <span className="">{t('main.vcard.registerkeynegdo')} </span>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  // rest of the code
};
export default Vcard;
